<template>
  <div class="calendar">
    <div class="header">
      <div class="header-changeDate">
        <i class="icon el-icon-arrow-left" @click="dateChange('reduce')"></i>
        <span class="time">{{ year }}年{{ month }}月</span>
        <i class="icon el-icon-arrow-right" @click="dateChange('add')"></i>
      </div>
      <div class="header-btnGroup">
        <p class="btn" :class="{'active-btn':btnGroupType==0}" @click="dateChange('reduce')">上个月</p>
        <p class="btn" :class="{'active-btn':btnGroupType==1}" @click="toMonth">本月</p>
        <p class="btn" :class="{'active-btn':btnGroupType==2}" @click="dateChange('add')">下个月</p>
      </div>
    </div>
    <div class="week">
      <p class="week-list" v-for="(item, index) in weekGroup" :key="'week' + index">{{ item }}</p>
    </div>
    <table class="table" v-if="attendance.length > 0">
      <tr class="table-row" v-for="(week, i) in calendarData" :key="i">
        <td class="table-chunk" v-for="(day, ii) in week" :key="ii">
          <p class="day__status">
            <span class="day" v-if="day.day">
              {{ `${day.day}`.slice(6) }}
            </span>
            <span class="status">
              {{ ['', '上班未打卡', '下班未打卡', '上班迟到', '下班早退', '', '待打卡', '请假'][day.status] }}
            </span>
          </p>
          <div class="commute">
            <p>{{ day | goWork }}</p>
            <p>{{ day | offWork }}</p>
          </div>
        </td>
      </tr>
    </table>
    <div v-else class="table-empty">暂未数据</div>
  </div>
</template>

<script>
import deepClone from '@/utils/clone.js'
export default {
  props: {
    y: {
      type: [String, Number],
      required: true
    },
    m: {
      type: [String, Number],
      required: true
    },
    attendance: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    }
  },

  watch: {
    attendance: {
      handler() {
        this.init()
      },
      immediate: true
    }
  },
  filters: {
    goWork(value) {
      let v = ''
      switch (value.status) {
        case '0':
          v = '上班：' + value.startime.split(' ')[1]
          break
        case '1':
          v = value.starstatus
          break
        case '2':
          v = '上班：' + value.startime.split(' ')[1]
          break
        case '3':
          v = value.starstatus
          break
        case '4':
          v = '上班：' + value.startime.split(' ')[1]
          break
        case '5':
          v = value.starstatus
          break
        case '6':
          v = '上班：' + value.starstatus
          break
        case '7':
          v = '无'
          break
      }
      return v
    },
    offWork(value) {
      let v = ''
      switch (value.status) {
        case '0':
          v = '下班：' + value.endtime.split(' ')[1]
          break
        case '1':
          v = '下班：' + value.endtime.split(' ')[1]
          break
        case '2':
          v = value.endstatus
          break
        case '3':
          v = value.endstatus
          break
        case '4':
          v = '下班：' + value.endtime.split(' ')[1]
          break
        case '6':
          v = '下班：' + value.endstatus
          break
      }
      return v
    }
  },
  data() {
    this.weekGroup = ['一', '二', '三', '四', '五', '六', '日']
    return {
      btnGroupType: 1,
      year: this.y,
      month: this.m,
      selectedDay: '',
      nowDay: '',
      calendar: [],
      monthStartWeek: '',
      monthEndWeek: '',
      calendarData: []
    }
  },
  methods: {
    init() {
      const attendance = deepClone(this.attendance)
      this.calendarData = []
      this.monthStartWeek = this.getDayOfWeek(this.year, this.month, 1)
      this.monthEndWeek = this.getDayOfWeek(this.year, this.month, attendance.length)
      if (this.monthStartWeek > 1) {
        for (let i = 1; i < this.monthStartWeek; i++) {
          attendance.unshift({})
        }
      }
      if (this.monthEndWeek < 7) {
        for (let i = 0; i < 7 - this.monthEndWeek; i++) {
          attendance.push({})
        }
      }
      let double = attendance.length / 7
      for (let i = 0; i < double; i++) {
        this.calendarData.push(attendance.splice(0, 7))
      }
    },
    changeDate() {
      this.$emit('change', this.year, this.month)
    },
    // 选中时间
    // selectDate(item) {
    //   if (item.isCurrentMonth) {
    //     this.selectedDay = `${this.year}-${this.month}-${item.day}`
    //     this.$emit('select', this.selectedDay)
    //   }
    // },
    toMonth() {
      this.year = this.y
      this.month = this.m
      this.btnGroupType = 1
      this.changeDate()
    },
    /**
     * @description: 日期的切换
     * @param {String} time: 要切换的时间
     * @param {String} change: 时间的加减
     */
    dateChange(change) {
      if (change === 'reduce') {
        this.btnGroupType = 0
        if (this.month == '1') {
          this.month = 12
          this.year--
        } else {
          this.month--
        }
      } else if (change === 'add') {
        this.btnGroupType = 2
        if (this.month == '12') {
          this.month = 1
          this.year++
        } else {
          this.month++
        }
      }
      this.changeDate()
    },
    // /**
    //  * @description: 获取当前日期
    //  * @return {*} [YYYY, D, M]
    //  */
    // getNowDate() {
    //   return new Date().toLocaleDateString().split('/')
    // },
    // /**
    //  * @description: 获取本月最后一天 ( 如果不传年月就是当前年月 )
    //  * @param {String: YYYY} year: 哪一年
    //  * @param {String: DD} month: 哪一月
    //  * @return {*} 最后一天的日期
    //  */
    // getMonthFinalDay(year, month) {
    //   let day = ''
    //   if (year == null || year == undefined || year == '') {
    //     year = new Date().getFullYear()
    //   }
    //   if (month == null || month == undefined || month == '') {
    //     month = new Date().getMonth() + 1
    //   }
    //   day = new Date(new Date(year, month).setDate(0)).getDate()
    //   return day
    // },
    /**
     * @description: 获取指定的日期是周几
     * @param {String: YYYY/DD/MM} date: 指定的日期
     * @return {*} 周几
     */
    getDayOfWeek(year, month, day) {
      let weekGroup = ['7', '1', '2', '3', '4', '5', '6']
      let week = new Date(`${year}/${month}/${day}`).getDay()
      return weekGroup[week]
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar {
  width: 75vw;
  border: 1px;
}

.active {
  background-color: #fdebe9;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #ffffff;
  border: none;
  .header-title {
    position: absolute;
    left: 0;
  }
  .header-changeDate {
    display: flex;
    align-items: center;
    .icon {
      cursor: pointer;
      font-size: 1.3rem;
    }
    .time {
      width: 13vw;
      text-align: center;
      font-size: 0.94rem;
    }
  }
  .header-btnGroup {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebf0f0;
    border-radius: 0.3vw;
    overflow: hidden;
    .btn {
      cursor: pointer;
      line-height: 1;
      padding: 8px 10px;
      font-size: 0.83vw;
      color: #666;
      border-radius: 0.31rem;
    }
    .active-btn {
      color: #fff;
      background: linear-gradient(215deg, #1db8ab, #179d92);
    }
    .btn-center {
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;

      background: linear-gradient(46deg, #1db8ab, #179d92);
      color: #ffffff;
    }
  }
}

.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 4.58rem;
  background-color: #f8f8f8;
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
  // font-size: 0.94rem;
  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.94rem;
  }
}
.table {
  width: 100%;
  background: #ffffff;
  tr td:first-child {
    border-left: 1px solid #ebeef5;
  }
  tr:first-child td {
    border-top: 1px solid #ebeef5;
  }

  td {
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    vertical-align: top;
    transition: background-color 0.2s ease;
  }
  .table-row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .table-chunk {
    height: 5.69rem;
    .day__status {
      padding: 0.8rem 0 0 2rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .day {
        font-size: 0.94rem;
        line-height: 1rem;
        font-weight: 400;
        color: #000000;
      }
      .status {
        padding-left: 1.2rem;
        font-size: 0.7rem;
        line-height: 1rem;
        font-weight: 400;
        color: #ff6c60;
      }
    }
    .commute {
      padding: 0.6rem 0 0 1.77rem;
      font-size: 0.73rem;
      font-weight: 400;
      color: #666666;
    }
  }
}
.table-empty {
  display: flex;
  align-items: center;
  font-size: 0.73rem;
  justify-content: center;
  color: #666666;
  width: 100%;
  height: 50px;
  border: 1px solid #ebeef5;
  background-color: #fff;
}
</style>
