import request from "./request";

export function getDataStatistic(data) {
	return request({
		url: "/workinglog/datastatistic",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}
