<template>
  <div class="attendanceRecords-page view-page">
    <div class="operate">
      <div class="icon-container">
        <div class="icon">{{ today }}天</div>
        <p class="txt">本月考勤记录</p>
      </div>
      <div class="statistics-container">
        <p>考勤：{{ workNum }}天</p>
        <i></i>
        <p>早退：{{ ztNum }}次</p>
        <i></i>
        <p>旷工：{{ kgNum }}次</p>
        <i></i>
        <p>缺卡：{{ qkNum }}次</p>
      </div>
    </div>
    <c-calendar v-if="isCalendar" :y="year" :m="month" :attendance="attendance" @change="changeDate"></c-calendar>
  </div>
</template>

<script>
import CCalendar from '@/components/calendar.vue'
import * as attendanceApi from '@/api/attendanceRecords.js'
export default {
  name: 'AttendanceRecords',
  components: { CCalendar },
  data() {
    return {
      year: '',
      month: '',
      day: '',
      isCalendar: false,
      attendance: [],
      kgNum: '',
      qkNum: '',
      workNum: '',
      ztNum: '',
      today: '' // 今天
    }
  },
  created() {
    this.init()
  },
  methods: {
    changeDate(year, month) {
      this.getDataStatistic(year, month)
    },
    init() {
      this.getDate()
      this.getDataStatistic(this.year, this.month)
    },
    /**
     * 得到当前日期
     */
    getDate() {
      const d = new Date()
      this.day = d.getDay()
      const year = d.getFullYear()
      let month = d.getMonth() + 1
      this.year = year
      this.month = month
      this.today = d.getDate()
    },
    getDataStatistic(year, month) {
      const param = {
        month: `${year}${month < 10 ? `0${month}` : month}`
      }
      attendanceApi.getDataStatistic(param).then(res => {
        this.kgNum = res.data.kgNum
        this.qkNum = res.data.qkNum
        this.workNum = res.data.workNum
        this.ztNum = res.data.ztNum
        this.isCalendar = true
        this.attendance = res.data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.attendanceRecords-page {
  padding-bottom: 50px;
  background-color: #fff;
}
.operate {
  position: relative;
  display: flex;
  align-items: center;
  height: 5.05rem;
  border-bottom: 1px solid #dbdbdb;
  .icon-container {
    display: flex;
    align-items: center;
    margin-left: 1.72rem;
    > .icon {
      width: 2.55rem;
      height: 2.71rem;
      text-align: center;
      line-height: 3.45rem;
      font-size: 0.73rem;
      font-weight: 400;
      color: #000000;
      background-image: url(../../assets/images/rests/data-bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    > .txt {
      padding-left: 0.78rem;
      font-size: 0.83rem;
      font-weight: 400;
      color: #000000;
      line-height: 1rem;
    }
  }
  .statistics-container {
    position: absolute;
    right: 7.79rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53.62rem;
    height: 2.71rem;
    background: #ebf0f0;
    border-radius: 0.42rem;
    > p {
      text-align: center;
      width: 10rem;
      font-size: 0.83rem;
      font-weight: 400;
      color: #000000;
      line-height: 1;
    }
    > i {
      display: block;
      width: 1px;
      height: 1.5rem;
      background-color: #adaeae;
    }
  }
}
.time {
  margin: 0 auto;
  width: 73.84rem;
  height: 4.27rem;
}
.calendar {
  margin: 0 auto;
  width: 73.84rem;
  background-color: #dbdbdb;
  .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 73.84rem;
    height: 4.5rem;
    background: #f8f8f8;
    border: 1px solid #cccccc;
    > p {
      text-align: center;
      line-height: 4.5rem;
      font-size: 0.94rem;
    }
  }
  .calendar-content {
    width: 73.84rem;
    background-color: #d0d0d0;
  }
}
</style>
